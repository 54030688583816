import Box from '@mui/material/Box';
import {CircularProgress, IconButton, Typography} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {EditIcon} from '@app/screens/Styles/style';
import Paper from '@mui/material/Paper';
import {useStyles} from '../styles';
import {Fragment, useEffect, useState} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import theme from '@app/themes';
import ReplayIcon from '@mui/icons-material/Replay';
import TextField from '@mui/material/TextField';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import {Stack} from '@mui/system';
import {RootStateOrAny, useSelector, useDispatch} from 'react-redux';
import {
  fetchFormApprovalReviewCreator,
  fetchFormRejectReviewDropDownCreator,
  fetchRequestFormInnerDataCreator,
  fetchReviewFormDeleteProductCreator,
  fetchReviewFormRevertProductCreator,
  fetchReviewFormUpdateAdditonalQtyCreator,
} from '../../redux-api/slice';
import {strings} from '@app/common/strings';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';

function ProductDetails({
  productData,
  userLoginInfo,
  requestFormId,
  approvalStatus,
  handleRejectToggle,
  handleToggleModal,
  hasDeactivatedProduct,
  setHasDeactivatedProduct,
  isEditProducts,
  setIsEditProducts,
  setIsEditDetails,
  isEditDetails,
}: any) {
  interface EditBodyItem {
    requestProductId: number;
    additionalQuantity: number;
  }
  const classes = useStyles();
  const [openCompetitor, setOpenCompetitor] = useState({id: 0, open: false});
  const [checked, setChecked] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isDeleteIconShow, setIsDeleteIconShow] = useState(false);

  const approvalData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.reviewFormApprovalData,
  );
  const [editBody, setEditBody] = useState<EditBodyItem[]>([]);
  const dispatch = useDispatch();

  const handleDeleteRow = (id: number, type: string, data: any) => {
    if (type !== 'revert') {
      setEditBody(prevEditBody =>
        prevEditBody?.filter(item => item?.requestProductId !== data.id),
      );
      dispatch(fetchReviewFormDeleteProductCreator(id, userLoginInfo?.userId));
    } else {
      setEditBody(prevEditBody => [
        ...prevEditBody,
        {requestProductId: id, additionalQuantity: data.additionalQuantity},
      ]);
      dispatch(
        fetchReviewFormRevertProductCreator({
          id,
          userId: userLoginInfo?.userId,
        }),
      );
    }
  };

  const checkHighlitedBorder = (isHighlited: boolean) => {
    return isHighlited ? classes.highlightBorderwithStatus : null;
  };

  const handleDetailsChange = (id: number, value: string) => {
    const numberRegex = /^\d*$/;

    if ((numberRegex.test(value) || value === '') && value.length <= 3) {
      const numericValue = value === '' ? 0 : Number(value);

      setEditBody(prevEditBody => {
        const updatedEditBody = [...prevEditBody];
        const index = updatedEditBody.findIndex(
          item => item.requestProductId === id,
        );

        if (index !== -1) {
          updatedEditBody[index].additionalQuantity = numericValue;
        } else {
          updatedEditBody.push({
            requestProductId: id,
            additionalQuantity: numericValue,
          });
        }

        return updatedEditBody;
      });
    }
  };

  const handleUpdateChange = () => {
    setIsUpdated(true);
    const readyToUpdate = editBody?.some(
      (product: any) => product.additionalQuantity === 0,
    );

    if (!readyToUpdate) {
      setIsEditProducts(false);
      setIsUpdated(false);
      dispatch(
        fetchReviewFormUpdateAdditonalQtyCreator(
          userLoginInfo?.userId,
          editBody,
        ),
      );
      setTimeout(() => {
        dispatch(fetchRequestFormInnerDataCreator(requestFormId, false));
      }, 200);
    } else {
      ToasterService.hideToaster();
      ToasterService.showToaster(
        "Additional Trade Discount can't be zero ",
        ToastType.ERROR,
      );
    }
  };

  const lastRowIndex =
    productData.products?.filter((data: any) => !data.isDeleted).length - 1;

  const handleApprovalReviewForm = () => {
    dispatch(
      fetchFormApprovalReviewCreator(
        userLoginInfo?.staffPositionId,
        userLoginInfo?.userId,
        [requestFormId],
        hasDeactivatedProduct ? 'pApproval' : 'approval',
      ),
    );
    handleToggleModal();
  };

  const handleRejectReviewForm = () => {
    dispatch(fetchFormRejectReviewDropDownCreator());
    handleRejectToggle();
    // handleToggleModal();
  };

  useEffect(() => {
    const initialEditBody = productData.products?.map((product: any) => ({
      requestProductId: product?.id,
      additionalQuantity: product?.additionalQuantity || 0,
    }));

    const hasDeactivated = productData?.products?.some(
      (product: any) => product.deactivatedByAdmin,
    );
    setEditBody(initialEditBody);

    if (!hasDeactivated) {
    }

    setHasDeactivatedProduct(hasDeactivated);

    areAllProductsDeactivated(productData);
    return () => {
      setIsEditProducts(false);
    };
  }, [productData]);

  function areAllProductsDeactivated(productData: any) {
    // Check if there are no products at all
    if (!productData?.products || productData.products.length === 0) {
      setIsDeleteIconShow(false);
      return;
    }

    // Check if there is at least one product that is not deactivated
    const activeProducts = productData.products.filter(
      (product: any) => !product.deactivatedByAdmin,
    );

    if (activeProducts.length === 1) {
      setIsDeleteIconShow(false);
    } else {
      setIsDeleteIconShow(true);
    }
  }

  const handleCancel = () => {
    setIsEditProducts(false);
    const initialEditBody = productData.products?.map((product: any) => ({
      requestProductId: product?.id,
      additionalQuantity: product?.additionalQuantity || 0,
    }));
    setEditBody(initialEditBody);
  };

  ///competitor accordion

  const renderCompetitorProducts = (data: any) => {
    return (
      openCompetitor.open &&
      openCompetitor.id === data.id &&
      data?.competitorProducts?.length > 0 &&
      data?.competitorProducts?.map((product: any, index: number) => (
        <TableRow key={index} className={`${classes.noWrap}`}>
          <TableCell
            sx={{padding: '0px'}}
            className={`${classes.productTableLeftBorder}  ${
              index === data?.competitorProducts.length - 1
                ? classes.borderBottomLeft
                : ''
            }`}></TableCell>
          <TableCell colSpan={8} className={`${classes.reviewCellPadding}`}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start">
              <Box sx={{minWidth: '168px', maxWidth: '168px'}}>
                <Typography
                  fontSize={'10.7px'}
                  fontWeight={600}
                  className={classes.defaultLightText}>
                  Competitor Product :
                </Typography>
                <Typography
                  sx={{textWrap: 'wrap', wordBreak: 'break-word'}}
                  fontSize={'12.7px'}
                  color={theme.colors.grey[200]}
                  fontWeight={'500'}
                  variant="subtitle2">
                  {product?.productName}
                </Typography>
              </Box>
              <Box
                sx={{marginLeft: '12px', minWidth: '180px', maxWidth: '180px'}}>
                <Typography
                  fontSize={'10.7px'}
                  fontWeight={600}
                  variant="subtitle2"
                  className={classes.defaultLightText}>
                  Competitor Company :
                </Typography>
                <Typography
                  sx={{textWrap: 'wrap', wordBreak: 'break-word'}}
                  variant="subtitle2"
                  fontSize={'12.7px'}
                  fontWeight={'400'}
                  color={theme.colors.grey[200]}>
                  {product?.companyName}
                </Typography>
              </Box>
            </Stack>
          </TableCell>
          <TableCell
            sx={{
              fontSize: '11.7px',
              color: theme.colors.grey[200],
            }}
            className={`${classes.reviewCellPadding}`}>
            {product?.totalQuantity}
          </TableCell>
          <TableCell
            sx={{
              fontSize: '11.7px',
              color: theme.colors.grey[200],
            }}
            className={`${classes.productTableRightBorder} ${
              classes.reviewCellPadding
            } ${
              index === data?.competitorProducts.length - 1
                ? classes.borderBottomRight
                : ''
            }`}>
            {product?.percentage}
          </TableCell>
        </TableRow>
      ))
    );
  };

  const renderProductCells = (data: any, rowIndex: number, pid: number) => {
    const findEditBodyItem = (requestProductId: number) =>
      editBody?.find(
        (item: EditBodyItem) => item?.requestProductId === requestProductId,
      );

    const editBodyItem = findEditBodyItem(pid);

    const isLastRow = rowIndex === lastRowIndex;
    const isCompetitorOpen =
      openCompetitor.open &&
      openCompetitor.id === data.id &&
      data?.competitorProducts?.length > 0;

    return (
      <>
        <TableCell
          sx={{padding: '8px 0px 8px 8px', minWidth: '25px'}}
          className={`${classes.proDetailCompDividerSmall} ${
            classes.productTableLeftBorder
          } ${
            isLastRow && !isCompetitorOpen ? classes.borderBottomLeft : ''
          } ${checkHighlitedBorder(data?.isHighlighted)}`}>
          {data.competitorProducts?.length > 0 && (
            <Box display="inline-block">
              {data?.competitorProducts?.length !== 0 && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    setOpenCompetitor({id: data.id, open: !isCompetitorOpen});
                  }}
                  style={{color: theme.colors.grey[3200], padding: '0px'}}>
                  {isCompetitorOpen ? (
                    <RemoveCircleOutlineOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  ) : (
                    <AddCircleOutlineOutlinedIcon
                      fontSize="small"
                      color="primary"
                    />
                  )}
                </IconButton>
              )}
            </Box>
          )}
        </TableCell>

        <TableCell
          sx={{minWidth: '180px', maxWidth: '180px'}}
          className={`${classes.proDetailCompDividerSmall} 
            ${classes.selectedProductsWidth} ${
            classes.reviewCellPadding
          } ${checkHighlitedBorder(data?.isHighlighted)}`}>
          <Box
            className={classes.priDefaultFontSize}
            sx={{textWrap: 'wrap'}}
            display="inline-block">
            {data?.productName}
          </Box>
        </TableCell>

        <TableCell
          style={{fontSize: '10.7px !important'}}
          sx={{
            fontSiz: '10.7px !important',
            fontWeight: '600',
            color: '#1c1939',
            minWidth: '60px',
            maxWidth: '60px',
          }}
          className={`${classes.proDetailCompDividerSmall} ${
            classes.reviewCellPadding
          } ${checkHighlitedBorder(data?.isHighlighted)}`}>
          {data?.mrp}
        </TableCell>

        <TableCell
          sx={{
            fontSiz: '10.7px !important',
            fontWeight: '600',
            color: '#1c1939',
            minWidth: '60px',
            maxWidth: '60px',
          }}
          className={`${classes.proDetailCompDividerSmall} ${
            classes.reviewCellPadding
          } ${checkHighlitedBorder(data?.isHighlighted)}`}>
          {data?.pts}
        </TableCell>

        <TableCell
          sx={{
            fontSiz: '10.7px !important',
            fontWeight: '600',
            color: '#1c1939',
            minWidth: '60px',
            maxWidth: '60px',
          }}
          className={`${classes.proDetailCompDividerSmall} ${
            classes.reviewCellPadding
          } ${checkHighlitedBorder(data?.isHighlighted)}`}>
          {data?.ptr}
        </TableCell>

        <TableCell
          sx={{minWidth: '60px', maxWidth: '60px'}}
          className={`${classes.proDetailCompDividerSmall} ${
            classes.proDetailsLeftBorder
          } ${classes.reviewCellPadding} ${checkHighlitedBorder(
            data?.isHighlighted,
          )}`}>
          {`${data?.stdBaseQuantity} + ${data?.stdBonusQuantity}`}
        </TableCell>

        <TableCell
          sx={{minWidth: '50px', maxWidth: '50px'}}
          className={`${classes.proDetailCompDividerSmall} ${
            classes.reviewCellPadding
          } ${checkHighlitedBorder(data?.isHighlighted)}`}>
          {(
            (data?.stdBonusQuantity /
              (data?.stdBaseQuantity + data?.stdBonusQuantity)) *
            100
          ).toFixed(2)}
        </TableCell>

        <TableCell
          sx={{minWidth: '60px', maxWidth: '60px'}}
          className={`${classes.proDetailCompDividerSmall} ${
            classes.proDetailsLeftBorder
          } ${
            !isEditProducts ? classes.reviewCellPadding : ''
          } ${checkHighlitedBorder(data?.isHighlighted)}`}>
          {isEditProducts ? (
            <TextField
              data-testid="edit-additional-discount"
              sx={{
                background: '#ffffff',
                outline: 'none',
                borderRadius: '5px',
                border:
                  isUpdated && editBodyItem?.additionalQuantity === 0
                    ? '2px solid red'
                    : '',
                '& .MuiInputBase-input': {
                  textAlign: 'center',
                  height: '30px',
                  width: '40px',
                  fontSize: '11.7px',
                  padding: '0px !important',
                },
              }}
              className={classes.productDetailsInput}
              onChange={e => {
                handleDetailsChange(data.id, e.target.value);
              }}
              onClick={e => e.stopPropagation()}
              InputProps={{
                style: {
                  height: '30px !important',
                  width: '40px !important',
                  marginLeft: '0px',
                },
              }}
              value={editBodyItem?.additionalQuantity}
              id="outlined-basic"
              label=""
              variant="outlined"
            />
          ) : (
            data.additionalQuantity
          )}
        </TableCell>

        <TableCell
          sx={{minWidth: '50px', maxWidth: '50px'}}
          className={`${classes.proDetailCompDividerSmall} ${
            classes.reviewCellPadding
          } ${checkHighlitedBorder(data?.isHighlighted)}`}>
          {editBody?.length > 0 &&
            (() => {
              const totalQuantity =
                data?.stdBaseQuantity +
                editBodyItem?.additionalQuantity +
                data?.stdBonusQuantity;
              const initialPercentage =
                (data?.stdBonusQuantity /
                  (data?.stdBaseQuantity + data?.stdBonusQuantity)) *
                100;
              const newPercentage =
                ((editBodyItem?.additionalQuantity + data?.stdBonusQuantity) /
                  totalQuantity) *
                100;

              // Round intermediate percentages to 2 decimal places before subtraction
              const roundedInitialPercentage = parseFloat(
                initialPercentage.toFixed(2),
              );
              const roundedNewPercentage = parseFloat(newPercentage.toFixed(2));

              // Calculate and round the percentage change to 2 decimal places
              const percentageChange =
                roundedNewPercentage - roundedInitialPercentage;

              return percentageChange.toFixed(2);
            })()}
        </TableCell>

        <TableCell
          sx={{minWidth: '60px', maxWidth: '60px'}}
          className={`${classes.proDetailCompDividerSmall} ${
            classes.proDetailsLeftBorder
          } ${classes.reviewCellPadding} ${checkHighlitedBorder(
            data?.isHighlighted,
          )}`}>
          {editBody?.length > 0 &&
            `${data?.stdBaseQuantity} + ${
              editBodyItem?.additionalQuantity + data?.stdBonusQuantity
            }`}
        </TableCell>

        <TableCell
          sx={{minWidth: '50px', maxWidth: '50px'}}
          className={`${
            classes.proDetailCompDividerSmall
          } ${checkHighlitedBorder(data?.isHighlighted)} ${
            classes.productTableRightBorder
          } ${
            isLastRow && !isCompetitorOpen ? classes.borderBottomRight : ''
          } ${classes.reviewCellPadding}`}>
          {editBody?.length > 0 &&
            (
              ((editBodyItem?.additionalQuantity + data?.stdBonusQuantity) /
                (data?.stdBaseQuantity +
                  editBodyItem?.additionalQuantity +
                  data?.stdBonusQuantity)) *
              100
            ).toFixed(2)}
        </TableCell>

        {approvalStatus === 0 && !isEditProducts && isDeleteIconShow && (
          <TableCell
            sx={{
              background: 'white',
              padding: '0px !important',
            }}
            className={`${classes.defaultText} ${classes.noBorder}`}>
            <IconButton
              sx={{padding: '0px', marginLeft: '8px'}}
              data-testid="delete-product"
              onClick={e => {
                e.stopPropagation();
                handleDeleteRow(data.id, '', data);
                if (
                  isEditProducts &&
                  !productData?.products?.every(
                    (product: any) => product.deactivatedByAdmin,
                  )
                ) {
                  setIsEditProducts(false);
                }
              }}
              style={{color: theme.colors.grey[3200]}}>
              <DeleteIcon sx={{color: '#D52929', cursor: 'pointer'}}/>
            </IconButton>
          </TableCell>
        )}
        {/* Add more TableCells here following the same pattern */}
      </>
    );
  };

  const deletedRenderProductCells = (
    data: any,
    rowIndex: number,
    pid: number,
  ) => {
    const findEditBodyItem = (requestProductId: number) =>
      editBody?.find(
        (item: EditBodyItem) => item?.requestProductId === requestProductId,
      );

    const editBodyItem = findEditBodyItem(pid);

    const isLastRow = rowIndex === lastRowIndex;
    const isCompetitorOpen =
      openCompetitor.open &&
      openCompetitor.id === data.id &&
      data?.competitorProducts?.length > 0;

    return (
      <>
        <TableCell
          sx={{padding: '8px 0px 8px 8px', minWidth: '25px'}}
          className={`${classes.proDetailCompDividerSmall} ${
            classes.productTableLeftBorder
          } ${isLastRow && !isCompetitorOpen ? classes.borderBottomLeft : ''} ${
            classes.deletedProBorderBottom
          }`}>
          {data.competitorProducts?.length > 0 && (
            <Box display="inline-block">
              {data?.competitorProducts?.length !== 0 && (
                <IconButton
                  onClick={e => {
                    e.stopPropagation();
                    setOpenCompetitor({
                      id: data.id,
                      open: !isCompetitorOpen,
                    });
                  }}
                  style={{
                    color: theme.colors.grey[3200],
                    padding: '0px',
                  }}>
                  {isCompetitorOpen ? (
                    <RemoveCircleOutlineOutlinedIcon
                      color="primary"
                      fontSize="small"
                    />
                  ) : (
                    <AddCircleOutlineOutlinedIcon
                      fontSize="small"
                      color="primary"
                    />
                  )}
                </IconButton>
              )}
            </Box>
          )}
        </TableCell>
        <TableCell
          sx={{
            fontSize: '12.7px',
            fontWeight: '400',
            minWidth: '180px',
            maxWidth: '180px',
          }}
          className={` ${classes.deletedProBorderBottom} ${classes.reviewCellPadding} `}>
          <Box
            className={classes.priDefaultFontSize}
            sx={{textWrap: 'wrap'}}
            display="inline-block">
            {data?.productName}
          </Box>
        </TableCell>
        <TableCell
          sx={{
            fontSize: '11.7px',
            fontWeight: '400',
            textWrap: 'nowrap',
            minWidth: '60px',
            maxWidth: '60px',
          }}
          className={`${classes.deletedProBorderBottom} ${classes.reviewCellPadding} `}>
          {data?.mrp}
        </TableCell>
        <TableCell
          sx={{
            fontSize: '11.7px',
            fontWeight: '400',
            textWrap: 'nowrap',
            minWidth: '60px',
            maxWidth: '60px',
          }}
          className={`${classes.deletedProBorderBottom} ${classes.reviewCellPadding} `}>
          {data?.pts}
        </TableCell>
        <TableCell
          sx={{
            fontSize: '11.7px',
            fontWeight: '400',
            textWrap: 'nowrap',
            minWidth: '60px',
            maxWidth: '60px',
          }}
          className={`${classes.deletedProBorderBottom} ${classes.reviewCellPadding} `}>
          {data?.ptr}
        </TableCell>
        <TableCell
          sx={{
            borderLeft: '1px solid #c2d1dc',
            fontSize: '11.7px',
            fontWeight: '400',
            textWrap: 'nowrap',
            minWidth: '60px',
            maxWidth: '60px',
          }}
          className={`${classes.deletedProBorderBottom} ${classes.reviewCellPadding} `}>
          {`${data?.stdBaseQuantity} ${'+'} ${data?.stdBonusQuantity}`}
        </TableCell>
        <TableCell
          sx={{
            fontSize: '11.7px',
            fontWeight: '400',
            textWrap: 'nowrap',
            minWidth: '50px',
            maxWidth: '50px',
          }}
          className={`${classes.deletedProBorderBottom} ${classes.reviewCellPadding} `}>
          {(
            (data?.stdBonusQuantity /
              (data?.stdBaseQuantity + data?.stdBonusQuantity)) *
            100
          ).toFixed(2)}
        </TableCell>
        <TableCell
          sx={{
            borderLeft: '1px solid #c2d1dc',
            fontSize: '11.7px',
            fontWeight: '400',
            textWrap: 'nowrap',
            minWidth: '60px',
            maxWidth: '60px',
          }}
          className={`${classes.deletedProBorderBottom} ${classes.reviewCellPadding} `}>
          {data.additionalQuantity}
        </TableCell>
        <TableCell
          sx={{
            fontSize: '11.7px',
            fontWeight: '400',
            textWrap: 'nowrap',
            minWidth: '50px',
            maxWidth: '50px',
          }}
          className={`${classes.deletedProBorderBottom} ${classes.reviewCellPadding} `}>
          {editBody?.length > 0 &&
            (() => {
              const totalQuantity =
                data?.stdBaseQuantity +
                editBodyItem?.additionalQuantity +
                data?.stdBonusQuantity;
              const initialPercentage =
                (data?.stdBonusQuantity /
                  (data?.stdBaseQuantity + data?.stdBonusQuantity)) *
                100;
              const newPercentage =
                ((editBodyItem?.additionalQuantity + data?.stdBonusQuantity) /
                  totalQuantity) *
                100;

              // Round intermediate percentages to 2 decimal places before subtraction
              const roundedInitialPercentage = parseFloat(
                initialPercentage.toFixed(2),
              );
              const roundedNewPercentage = parseFloat(newPercentage.toFixed(2));

              // Calculate and round the percentage change to 2 decimal places
              const percentageChange =
                roundedNewPercentage - roundedInitialPercentage;

              return percentageChange.toFixed(2);
            })()}
        </TableCell>
        <TableCell
          sx={{
            borderLeft: '1px solid #c2d1dc',
            fontSize: '11.7px',
            fontWeight: '400',
            textWrap: 'nowrap',
            minWidth: '60px',
            maxWidth: '60px',
          }}
          className={`${classes.deletedProBorderBottom} ${classes.reviewCellPadding} `}>
          {editBody?.length > 0 &&
            ` ${data?.stdBaseQuantity} ${'+'} ${
              data?.additionalQuantity + data?.stdBonusQuantity
            }`}
        </TableCell>
        <TableCell
          sx={{
            fontSize: '11.7px',
            fontWeight: '400',
            textWrap: 'nowrap',
            minWidth: '50px',
            maxWidth: '50px',
          }}
          className={`${classes.deletedProBorderBottom} ${
            classes.productTableRightBorder
          }  ${rowIndex === lastRowIndex ? classes.borderBottomRight : ''} ${
            classes.reviewCellPadding
          }`}>
          {editBody?.length > 0 &&
            (
              ((editBodyItem?.additionalQuantity + data?.stdBonusQuantity) /
                (data?.stdBaseQuantity +
                  editBodyItem?.additionalQuantity +
                  data?.stdBonusQuantity)) *
              100
            ).toFixed(2)}
        </TableCell>

        {approvalStatus === 0 && (
          <TableCell sx={{border: 'none', padding: '0px'}}>
            <IconButton
              sx={{padding: '0px', marginLeft: '8px'}}
              data-testid="undo-delete"
              onClick={e => {
                e.stopPropagation();
                handleDeleteRow(data.id, 'revert', data);
              }}
              style={{color: theme.colors.grey[3200]}}>
              <ReplayIcon color="primary" fontSize="small" />
            </IconButton>
          </TableCell>
        )}
      </>
    );
  };

  return (
    <>
      <Box className={classes.headingMarginBottom}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{marginBottom: '16px'}}>
          <Typography
            sx={{marginBottom: '0px !important', fontSize: '15.7px'}}
            className={`${classes.RfHeading} ${classes.noWrap} `}
            variant="h6">
            {strings.zho.productDetails}
            {approvalStatus === 0 && !isEditProducts && (
              <EditIcon
                data-testid="edit-product-details"
                onClick={() => {
                  if (
                    productData?.products?.every(
                      (product: any) => product?.deactivatedByAdmin,
                    )
                  ) {
                  } else {
                    setIsEditProducts(true);
                    setIsEditDetails(false);
                  }
                }}
                className={classes.editIcon}
                color="primary"
              />
            )}
          </Typography>
          {approvalStatus === 0 && (
            <Box>
              <Button
                onClick={handleCancel}
                data-testid="button-cancel"
                variant="outlined"
                size="large"
                disabled={isEditProducts ? false : true}
                className={classes.paperButton}>
                {strings.cancel}
              </Button>
              <Button
                onClick={handleUpdateChange}
                data-testid="button-save"
                variant="contained"
                disabled={isEditProducts ? false : true}
                className={classes.applyButton}>
                {strings.save}
              </Button>
            </Box>
          )}
        </Stack>
        <TableContainer className={`${classes.noBoxShd}`} component={Paper}>
          <Table
            className={classes.productDetailsTableSpacing}
            aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{background: 'white', border: '1px solid #E2E2E2'}}
                  colSpan={5}
                  className={`${classes.defaultText} ${classes.noBorderBottom} ${classes.borderTopLeft} ${classes.reviewCellPadding}`}></TableCell>
                <TableCell
                  colSpan={2}
                  className={`${classes.proDetailsTableTopHeadings} ${classes.proDetailsLeftBorder} ${classes.reviewCellPadding}`}>
                  {strings.zho.standardTradeDiscount}
                </TableCell>
                <TableCell
                  colSpan={2}
                  className={`${classes.proDetailsTableTopHeadings} ${classes.proDetailsLeftBorder} ${classes.reviewCellPadding}`}>
                  {strings.zho.additionalradeDiscount}
                </TableCell>
                <TableCell
                  colSpan={2}
                  className={`${classes.proDetailsTableTopHeadings} ${classes.proDetailsLeftBorder} ${classes.borderTopRight} ${classes.reviewCellPadding}`}>
                  {strings.zho.totalTradeDiscount}
                </TableCell>
                <TableCell
                  className={`${classes.defaultText} ${classes.noBorder}`}></TableCell>
              </TableRow>

              <TableRow className={`${classes.proDeatilsThead}`}>
                {/* <TableCell
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.productTableLeftBorder} `}></TableCell> */}
                <TableCell
                  sx={{padding: '0px', minWidth: '34.3px'}}
                  className={` ${classes.noBorderBottom} ${classes.productTableLeftBorder}`}></TableCell>
                <TableCell
                  sx={{
                    textTransform: 'uppercase',
                    fontSize: '10.7px !important',
                  }}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsHeading} ${classes.reviewCellPadding}`}>
                  {strings.zho.selectedproducts}
                </TableCell>
                <TableCell
                  sx={{fontSize: '10.7px !important'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.reviewCellPadding}`}>
                  {strings.zho.mrp}
                </TableCell>
                <TableCell
                  sx={{fontSize: '10.7px !important'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.reviewCellPadding}`}>
                  {strings.zho.pts}
                </TableCell>
                <TableCell
                  sx={{fontSize: '10.7px !important'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.reviewCellPadding}`}>
                  {strings.zho.ptr}
                </TableCell>
                <TableCell
                  sx={{fontSize: '10.7px !important'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.reviewCellPadding}`}>
                  {strings.zho.qty}
                </TableCell>
                <TableCell
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.reviewCellPadding}`}>
                  {strings.zho.percent}
                </TableCell>
                <TableCell
                  sx={{fontSize: '10.7px !important'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.reviewCellPadding}`}>
                  {strings.zho.qty}
                </TableCell>
                <TableCell
                  sx={{fontSize: '10.7px !important'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.reviewCellPadding}`}>
                  {strings.zho.percent}
                </TableCell>
                <TableCell
                  sx={{fontSize: '10.7px !important'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.reviewCellPadding}`}>
                  {strings.zho.qty}
                </TableCell>
                <TableCell
                  sx={{fontSize: '10.7px !important'}}
                  className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.productTableRightBorder} ${classes.reviewCellPadding}`}>
                  {strings.zho.percent}
                </TableCell>
                {/* <TableCell
                  sx={{fontSize: '10.7px !important'}}
                  className={`${classes.defaultText} ${classes.noBorder} `}></TableCell> */}
              </TableRow>
            </TableHead>

            <TableBody className={`${classes.productDetailsTableBody}`}>
              {productData?.products?.length > 0 &&
              !productData?.products?.every(
                (product: any) => product.deactivatedByAdmin,
              ) ? (
                productData?.products
                  ?.filter((product: any) => !product.deactivatedByAdmin)
                  .map((data: any, rowIndex: number) => (
                    <Fragment key={rowIndex}>
                      <TableRow>
                        {renderProductCells(data, rowIndex, data.id)}
                      </TableRow>
                      {renderCompetitorProducts(data)}
                    </Fragment>
                  ))
              ) : (
                <TableRow sx={{borderRight: '1px solid #e2e2e2'}}>
                  <TableCell
                    sx={{
                      textAlign: 'center',
                      borderLeft: '1px solid #e2e2e2 !important',
                      borderRight: '1px solid #e2e2e2',
                    }}
                    colSpan={11}>
                    Currently there is no Product Data!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* Deleted products table */}

      {hasDeactivatedProduct && (
        <Box className={`${classes.flexCol} ${classes.headingMarginBottom} `}>
          <Typography
            fontSize="15.7px"
            color="#322b7c"
            className={`${classes.RfHeading} ${classes.headingMarginBottom}`}
            variant="h6">
            {strings.zho.deletedProducts}
          </Typography>
          <TableContainer className={`${classes.noBoxShd}`} component={Paper}>
            <Table
              className={classes.productDetailsTableSpacing}
              aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{background: 'white', border: '1px solid #E2E2E2'}}>
                  <TableCell
                    sx={{background: 'white', border: '1px solid #E2E2E2'}}
                    colSpan={5}
                    className={`${classes.defaultText} ${classes.noBorderBottom} ${classes.borderTopLeft} `}></TableCell>
                  <TableCell
                    colSpan={2}
                    className={` ${classes.proDetailsTableTopHeadings} ${classes.proDetailsLeftBorder}`}>
                    {strings.zho.standardTradeDiscount}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    className={`${classes.proDetailsTableTopHeadings} ${classes.proDetailsLeftBorder}`}>
                    {strings.zho.additionalradeDiscount}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    className={`${classes.proDetailsTableTopHeadings} ${classes.proDetailsLeftBorder} ${classes.borderTopRight} ${classes.proDetailsRightBorder} `}>
                    {strings.zho.totalTradeDiscount}
                  </TableCell>
                  <TableCell
                    className={`${classes.defaultText} ${classes.noBorderBottom}  `}></TableCell>
                </TableRow>

                <TableRow className={`${classes.proDeatilsThead}`}>
                  <TableCell
                    sx={{padding: '0px', minWidth: '34.3px'}}
                    className={` ${classes.noBorderBottom} ${classes.productTableLeftBorder}`}></TableCell>
                  <TableCell
                    sx={{
                      textTransform: 'uppercase',
                      fontSize: '10.7px !important',
                    }}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsHeading} ${classes.reviewCellPadding}`}>
                    {strings.zho.selectedproducts}
                  </TableCell>
                  <TableCell
                    sx={{fontSize: '10.7px !important'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.reviewCellPadding}`}>
                    {strings.zho.mrp}
                  </TableCell>
                  <TableCell
                    sx={{fontSize: '10.7px !important'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.reviewCellPadding}`}>
                    {strings.zho.pts}
                  </TableCell>
                  <TableCell
                    sx={{fontSize: '10.7px !important'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.reviewCellPadding}`}>
                    {strings.zho.ptr}
                  </TableCell>
                  <TableCell
                    sx={{fontSize: '10.7px !important'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.reviewCellPadding}`}>
                    {strings.zho.qty}
                  </TableCell>
                  <TableCell
                    sx={{fontSize: '10.7px !important'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.reviewCellPadding}`}>
                    {strings.zho.percent}
                  </TableCell>
                  <TableCell
                    sx={{fontSize: '10.7px !important'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.reviewCellPadding}`}>
                    {strings.zho.qty}
                  </TableCell>
                  <TableCell
                    sx={{fontSize: '10.7px !important'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.reviewCellPadding}`}>
                    {strings.zho.percent}
                  </TableCell>
                  <TableCell
                    sx={{fontSize: '10.7px !important'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.proDetailsLeftBorder} ${classes.reviewCellPadding}`}>
                    {strings.zho.qty}
                  </TableCell>
                  <TableCell
                    sx={{fontSize: '10.7px !important'}}
                    className={`${classes.defaultLightText} ${classes.noBorderBottom} ${classes.productTableRightBorder} ${classes.reviewCellPadding}`}>
                    {strings.zho.percent}
                  </TableCell>
                  <TableCell className={` ${classes.noBorder}`}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productData?.products
                  ?.filter((product: any) => product?.deactivatedByAdmin)
                  .map((data: any, rowIndex: number) => {
                    return (
                      <Fragment key={rowIndex}>
                        <TableRow className={`${classes.tedProBorder}`}>
                          {deletedRenderProductCells(data, rowIndex, data.id)}
                        </TableRow>
                        {renderCompetitorProducts(data)}
                      </Fragment>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      {approvalStatus === 0 && (
        <Stack direction="row" justifyContent="flex-end">
          <Button
            disabled={isEditProducts || isEditDetails}
            data-testid="reject-form"
            variant="outlined"
            size="large"
            className={classes.paperButton}
            onClick={handleRejectReviewForm}>
            {strings.reject}
          </Button>
          <Button
            disabled={isEditProducts || isEditDetails}
            data-testid="approve-form"
            variant="contained"
            onClick={handleApprovalReviewForm}
            className={classes.applyButton}>
            {hasDeactivatedProduct ? 'Partial Approve' : 'Approve'}
          </Button>
        </Stack>
      )}
      {approvalStatus === 3 && (
        <>
          <Typography
            sx={{
              marginBottom: '16px !important',
              fontSize: '15.7px !important',
            }}
            className={`${classes.RfHeading} ${classes.noWrap} `}
            variant="h6">
            {strings.moduleSpecificUtilities.remarks}
          </Typography>
          <Box
            sx={{
              marginBottom: '16px',
            }}>
            <Box
              sx={{
                border: '1px solid #e2e2e2',
                borderRadius: '10px',
                width: '100%',

                padding: '0.8rem',
              }}>
              <Box>
                <Typography
                  display="inline-block"
                  sx={{
                    marginBottom: '0px !important',
                  }}
                  className={classes.defaultLightText}
                  variant="subtitle2">
                  {strings.zho.fcAdmin.requestForm.fcAdminRemarks}
                </Typography>
                <Typography
                  display="inline-block"
                  sx={{
                    marginBottom: '0px !important',
                    marginLeft: '2rem',
                    textTransform: 'capitalize !important',
                  }}
                  className={`${classes.priDefaultFontSize} ${classes.noWrap} `}
                  variant="subtitle2">
                  {approvalData?.rejectedRemarks}
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default ProductDetails;
