export const strings = {
  home: 'Home',
  reject: 'Reject',
  date: 'Date',
  division: 'Division',
  createdBy: 'Created By',
  designation: 'Designation',
  hq: 'HQ',
  taPerKm: 'TA per km',
  effectiveForm: 'Effective From',
  product: 'Product',
  validUpto: 'Valid Upto',
  status: 'Status',
  reset: 'Reset',
  save: 'Save',
  inActive: 'In-Active',
  active: 'Active',
  AddNew: 'Add New',
  get: ' Get',
  noRecordFound: 'No Records Found',
  amount: 'Amount',
  hqType: 'HQ Type',
  HQType: 'hqType',
  yes: 'Yes',
  no: 'No',
  confirm: 'Confirm',
  continueMsg: 'Are you sure you want to continue?',
  ok: 'Ok',
  cancel: 'Cancel',
  divisionName: 'divisionName',
  designationName: 'designationName',
  hqName: 'hqName',
  amount1: 'amount',
  hqTypeName: 'hqTypeName',
  startDate: 'startDate',
  endDate: 'endDate',
  add: 'Add',
  edit: 'Edit',
  Go: 'Go',
  DownloadExcel: ' Download Excel',
  update: 'Update',
  updateSuccessfull: 'Updated Successfully',
  expenseTypeName: 'Expense Type Name',
  proRataEnable: 'Pro Rata Enabled',
  actions: 'Actions',
  proRataMiscExpense: 'Pro Rata Misc Expense',
  navBarProRataMiscExpense: 'Pro Rata Misc. Expense',
  enableProRata: 'Enable Pro Rata',
  close: 'Close',
  go: 'Go',
  bill: 'Bill',
  approve: 'Approve',
  ex: 'Ex',
  view: 'View',
  out: 'Out',
  start: 'Start',
  end: 'End',
  distance: 'Distance(km)',
  hide: 'Hide',
  details: 'Details',
  ta: 'TA',
  da: 'DA',
  TA: 'ta',
  DA: 'da',
  DISTANCE: 'Distance',
  km: 'km',
  dialy: 'daily',
  HOLIDAY: 'HOLIDAY',
  WEEKOFF: 'WEEK OFF',
  WEEKOFFHOLIDAY: 'WEEK OFF/HOLIDAY',
  LEAVE: 'LEAVE',
  remarks: 'REMARKS',
  WEEKOFFHOLIDAYLEAVE: 'WEEK OFF/HOLIDAY/LEAVE',
  POLICYVIOLATION: 'POLICY VIOLATION',
  EDITABLEROW: 'EDITABLE ROW',
  amountClaimed: 'amountClaimed',
  amountManagerPassed: 'amountManagerPassed',
  amountAdminPassed: 'amountAdminPassed',
  standard: 'standard',
  claimed: 'claimed',
  InvalidAmount: 'Invalid Amount',
  SelectAll: 'Select All',
  ALL: 'ALL',
  MonthYear: 'Month-Year',
  State: 'State',
  SearchStaff: 'Search Staff',
  TAAdjustment: 'TA Adjustment',
  DAAdjustment: 'DA Adjustment',
  UnlockDeduction: 'Unlock Deduction',
  upload: 'upload',
  AdminChangeReasonisMandatory: 'Admin Change Reason is Mandatory',
  ExpenseApproval: 'Expense Approval',
  asc: 'asc',
  desc: 'desc',
  DailyExpReport: 'Daily Exp Report',
  MonthlyExpReport: 'Monthly Exp Report',
  ConvertintoExcel: 'Convert into Excel',
  Next: 'Next',
  Download: 'Download',
  DownloadAll: 'Download All',
  ExporttoExcel: 'Export to Excel',
  ExporttoExcelALL: 'Export to Excel - ALL',
  DailyExpenseReportpdf: 'Daily Expense Report.pdf',
  MonthlyExpenseReportpdf: 'Monthly Expense Report.pdf',
  DATotal: 'DA Total',
  DistanceTotal: 'Distance Total',
  GrandTotal: 'Grand Total',
  TATotal: 'TA Total',
  ExpenseTotal: 'Expense Total',
  templateDownloading: 'Template downloading...',
  templateDownloaded: 'Template downloaded successfully!',
  label: {
    startDate: 'start date',
    endDate: 'end date',
    billVisible: 'Bill Visible',
    MonthYear: 'Month-Year',
  },
  tableHeader: {
    division: 'division',
    designation: 'designation',
    hq: 'hq',
    taPerKM: 'taPerKM',
  },
  placeholder: {
    division: 'Search division',
    designation: 'Search designation',
    hq: 'Search HQ',
    hqType: 'Search HQ Type',
    enterValue: 'Enter Value',
  },
  masterDataManagement: {
    expenseDaMaster: 'Expense DA Master',
    expenseTaMaster: 'Expense TA Master',
    expenseTAMasterId: 'expenseTAMasterId',
    somethingWrongInDa: 'Something went wrong while Expense DA Master!',
    expenseDaActive: 'Expense DA Master is Active',
    expenseDaInActive: 'Expense DA Master is In-Active',
    expenseTaActive: 'Expense TA Master is Active',
    expenseTaInActive: 'Expense TA Master is In-Active',
    somethingWrongActiveInActiveMsg:
      'Something went wrong while Active/InActive Expense DA Master!',
    somethingWrongActiveInActiveTAMsg:
      'Something went wrong while Active/InActive Expense TA Master!',
    somethingWrongAddingDaMasterMsg:
      'Something went wrong while adding Expense DA Master!',
    somethingWrongAddingTaMasterMsg:
      'Something went wrong while adding Expense TA Master!',
    somethingWrongUpdatingDaMasterMsg:
      'Something went wrong while updating Expense DA Master',
    somethingWrongUpdatingTaMasterMsg:
      'Something went wrong while updating Expense TA Master',
    somethingWrongEditingDaMasterMsg:
      'Something went wrong while editing Expense DA Master!',
    somethingWrongEditingTaMasterMsg:
      'Something went wrong while editing Expense TA Master!',
    updateSuccessfull: 'Updated Successfully',
  },
  componentManagement: {
    expenseType: 'Expense Type',
    expenseName: 'Expense Name',
    expenseCategory: 'Expense Category',
    billRequired: 'Bill Required',
    action: 'Action',
    updateExpenseTypeMsg: 'Something went wrong while updating Expense Type',
    editExpenseTypeMsg: 'Something went wrong while editing Expense Type!',
    addExpenseTypeMsg: 'Something went wrong while adding Expense Type!',
    activeInactiveExpenseTypeMsg:
      'Something went wrong while Active/InActive Expense Type!',
    activeExpenseType: 'Expense Type is Active',
    inActiveExpenseType: 'Expense Type is In-Active',
    fetchingDataMsg: 'Something went wrong while fetching admin data!',
    columns: {
      expenseTypeName: 'expenseTypeName',
      expenseCategory: 'expenseCategory',
      expenseType: 'expenseType',
      isBillRequired: 'isBillRequired',
    },
  },
  moduleSpecificUtilities: {
    invoiceNumber: 'invoiceNumber',
    INVOICENUMBER: 'Invoice Number',
    invoiceDate: 'invoiceDate',
    INVOICEDATE: 'Invoice Date',
    VENDORNAME: 'Vendor Name',
    vendorName: 'vendorName',
    vendor: 'vendor',
    backspace: 'Backspace',
    invoiceTotalAmount: 'invoiceTotalAmt',
    INVOICEAMOUNT: 'Invoice Amount',
    greaterThan3MB:
      'File Size is greater than 3MB. Please Upload File Below 3MB',
    billNotSubmitted: "'The Bill is not Submitted. Please try again",
    vaildate: 'Validate',
    uploadBill: 'Upload Bills',
    validationSuccess: 'Validation Successful',
    supportingDoc: 'Supporting Documents',
    dailyCallReport: 'Daily Call Report',
    noData: 'No Data',
    summary: 'Summary',
    fieldActivity: 'Field Activity',
    nonFieldActivity: 'Non-Field Activity',
    aboutToApprove: 'You are about to approve the',
    aboutToReject: 'You are about to reject the',
    expenseOfMonth: 'expenses for the month of',
    daApproved: 'DA APPROVED',
    taApproved: 'TA APPROVED',
    miscApproved: 'MISC. APPROVED',
    systemGeneratedAmount: 'System Generated Amount',
    adminApprovedAmount: 'Admin Approved Amount',
    certifyByMe: 'I certify that expenses are validated by me',
    invoiceAmount: 'invoiceAmt',
    remarks: 'Remarks',
    noDataFound: 'No Data Found',
    insufficientFund: 'Insufficient Funds',
    preview: 'Preview',
    claimedAmount: 'Claimed Amount',
    minMaxChar: 'Please enter minimum 5 and maximum 50 characters',
    tarvelDetails: 'Travel Details',
    billDeleteSuccess: 'The Bill is Successfully deleted...',
    uploadedBill: 'Uploaded Bills',
    noBillFound: 'No Bills Found',
    nightStay: 'Night stay',
    stationCovered: 'Stations Covered',
    adminPassedAmount: 'adminApprovedAmount',
    ADMINPASSEDDA: 'ADMIN PASSED DA',
    ADMINPASSEDTA: 'ADMIN PASSED TA',
    adminRemarks: 'adminRemarks',
    hqTypeAdmin: 'hqTypeAdmin',
    HQTYPEADMIN: 'HQ TYPE ADMIN',
    outStation: 'Out-Station',
    o: 'O',
    homeStation: 'Home Station',
    HOMESTATION: 'Home-Station',
    H: 'H',
    exStation: 'Ex-Station',
    taDatPopup: 'taDatPopup',
    systemAmount: 'systemAmount',
    customAmount: 'customAmount',
    DACLAIMED: 'DA CLAIMED',
    TACLAIMED: 'TA CLAIMED',
    REMARKS: 'remarks',
    DACHANGEREASON: 'DA CHANGE REASON',
    TACHANGEREASON: 'TA CHANGE REASON',
    managerApprovedAmount: 'managerApprovedAmount',
    MANAGERPASSEDDA: 'MANAGER PASSED DA',
    MANAGERPASSEDTA: 'MANAGER PASSED TA',
    managerRemarks: 'managerRemarks',
    MANAGERREMARKS: 'MANAGER CHANGE REASON DA',
    MANAGERREMARKSTA: 'MANAGER CHANGE REASON TA',
    billpath: 'billpath',
    bill: 'bill',
    bills: 'Bills',
    simplePopover: 'simple-popover',
    availednightstay: 'availednightstay',
    returnedhome: 'returnedhome',
    RETURNEDHOME: 'Returned Home',
    AVAILEDNIGHTSTAY: 'Availed Night stay',
    totaldistance: 'totaldistance',
    ADMINPASSEDDISTANCE: 'ADMIN PASSED DISTANCE',
    dailyExpenseReport: 'Daily Expense Report',
    monthlyExpenseReport: 'Monthly Expense Report',
    staffName: 'STAFF NAME:',
    sapCode: 'SAP CODE -',
    designation: 'DESIGNATION:',
    division: 'DIVISION:',
    hq: 'HQ:',
    month: 'MONTH:',
    expNum: 'Exp No.',
    passedBy: 'Passed By',
    passedOn: 'Passed On',
    approvedBy: 'Approved By',
    approvedOn: 'Approved On',
    approved: 'Approved',
    rejected: 'Rejected',
    unapproved: 'Unapproved',
    all: 'All',
    adminPassedTA: 'adminPassedTA',
    adminPassedDA: 'adminPassedDA',
    adminPassedDistance: 'adminPassedDistance',
    ArrowLeft: 'ArrowLeft',
    ArrowRight: 'ArrowRight',
    expenseDay: 'expenseDay',
    Day: 'Day',
    activityName: 'activityName',
    Activity: 'Activity',
    fromStation: 'fromStation',
    FROMSTATION: 'From Station',
    toStationDisplayStr: 'toStationDisplayStr',
    TOSTATION: 'To Station',
    distance: 'distance',
    taClaimed: 'taClaimed',
    taChangeReason: 'taChangeReason',
    managerPassedTA: 'managerPassedTA',
    managerChangeReasonTA: 'managerChangeReasonTA',
    adminChangeReasonTA: 'adminChangeReasonTA',
    dAclaimed: 'dAclaimed',
    dAchangeReason: 'dAchangeReason',
    managerPassedDA: 'managerPassedDA',
    managerPassedReasonDA: 'managerPassedReasonDA',
    adminChangeReasonDA: 'adminChangeReasonDA',
    ADMINCHANGEREASONDA: 'ADMIN CHANGE REASON DA',
    ADMINCHANGEREASONTA: 'ADMIN CHANGE REASON TA',
    workWith: 'workWith',
    WORKWITH: 'WORK WITH',
    noOfDoctors: 'noOfDoctors',
    NOOFDOCTORS: 'NO. OF DOCTORS',
    noofChemists: 'noofChemists',
    NOOFCHEMISTS: 'NO. OF CHEMISTS',
    wsfaRemarks: 'wsfaRemarks',
    DCRRemarks: 'DCR Remarks',
    taRate: 'taRate',
    TARATE: 'TA RATE',
    billPath: 'billPath',
    BILL: 'BILL',
    AdminChangeReasonTAisMandator: 'Admin Change Reason TA is Mandator',
    AdminChangeReasonDAisMandatory: 'Admin Change Reason DA is Mandatory',
    travelItems: 'travelItems',
    ExceptionSummaryReport: 'Exception Summary Report',
    ExceptionSummarypdf: 'Exception Summary.pdf',
    ExpenseApprovalDaily: 'Expense Approval Daily',
    ExpenseApprovalMisc: 'Expense Approval Misc',
    ExceptionSummary: 'Exception Summary',
    DeductDAAmount: 'Deduct DA Amount',
    MonthlyReport: 'Monthly Report',
    DailyReport: 'Daily Report',
    ApproveExpenseMonthly: 'ApproveExpenseMonthly',
    ApproveExpenseDaily: 'ApproveExpenseDaily',
    billIsSaving: 'Uploading Bill...',
  },
  ModuleSpecificUtilitie: {
    DownloadPdf: 'Download Pdf',
    ExportToExcel: 'Export To Excel',
    NoRecordsFound: '  No Records Found',
    From: 'From',
    To: 'To',
    approveExpense: {
      selectStaff: 'Select Staff',
      TotalTA: 'Total TA',
      TotalDA: 'Total DA',
      MiscAmt: 'Misc Amt',
      Total: 'Total',
      TotalTAClaim: 'Total TA Claim',
      TotalDAClaim: 'Total DA Claim',
      MiscAmtClaimed: 'Misc Amt Claimed',
      TotalAmtClaimed: 'Total Amt Claimed',
      TotalTAPassedManager: 'Total TA Passed Manager',
      TotalDAPassedManager: ' Total DA Passed Manager',
      TotalMiscAmtPassedManager: 'Total Misc Amt Passed Manager',
      TotalAmtPassedManager: 'Total Amt Passed Manager',
      TotalTAPassedAdmin: 'Total TA Passed Admin',
      TotalDAPassedAdmin: 'Total DA Passed Admin',
      TotalMiscPassedAdmin: 'Total Misc Passed Admin',
      TotalAmtPassedAdmin: 'Total Amt Passed Admin',
      TotalDistance: 'Total Distance',
      PassedByAdmin: 'Passed By Admin',
      Ex: 'Ex',
      out: 'Out',
      Approve: 'Approve',
      Reject: 'Reject',
      ADMINCHANGEREASONTA: 'ADMIN CHANGE REASON TA : ',
      ADMINCHANGEREASONDA: 'ADMIN CHANGE REASON DA : ',
      Missingreason: ' Missing reason for day/s : ',
      CHANGEREASONBYADMIN: 'CHANGE REASON BY ADMIN : ',
      msg: 'please fill the reason for edited fields in Miscellaneous Gird',
      msg1: 'Please provide remarks for mentioned days to proceed further',
    },
    reverseExpensepage: {
      reverseExpense: 'Reverse Expense',
      STAFF_NAME: 'STAFF NAME',
      EMPLOYEE_CODE: 'EMPLOYEE CODE',
      DIVISION: 'DIVISION',
      Employee_Name: 'Employee Name',
      Expense_Status: ' Expense Status',
      SAP_CODE: 'SAP CODE',
      DESIGNATION: 'DESGINATION',
      HQ: 'HQ',
    },
    generatepage: {
      generateReport: 'Generate Report',
      MONTH: 'Month',
      YEAR: 'Year',
    },
    expenseReportPage: {
      expenseReport: ' Expense Reports',
      TA_Summary: 'TA Summary',
      Yearly_Disbursement_Summary: 'Yearly Disbursement Summary',
      Expense_Bifurcation: 'Expense Bifurcation',
      Staff: 'Staff',
      Date: 'Date',
      mmyy: 'mm/yy',
      Division: 'Division',
      MONTHWISETACLAIMVSTAADMINPASS: 'MONTH WISE TA CLAIM VS TA ADMIN PASS',
    },
    expenseFileNumberPage: {
      expenseFileNumber: 'Expense File Number',
      browseFileName: 'Browse File Name',
      downloadTemplate: 'Download Template',
      downloadReport: 'Download Report',
      errmsg: 'The File Is Empty/Mandatory Value Is Not Available',
      duplicatecheck: 'Duplicate exist. Please check',
      validNameTemp: 'Expense_File_Number_Template',
      invalidFile: 'The file format is invalid.',
      uploadFile: 'Upload csv or xlsx file',
      fileName: 'Expense_File_Number_Report.xlsx',
    },
    expense: {
      Expense: 'Expense',
      PARAMETER: 'PARAMETER',
      MONTH: 'MONTH',
      YEAR: 'YEAR',
      Pleaseselectparameter: 'Please select parameter',
    },
    billLibrary: {
      clickheretodownload: 'Click Here To Download',
      noBillFound: 'No Bill Found',
      billlibrary: 'Bill Library',
    },
  },
  zho: {
    requestForm: ' Request FORM',
    products: 'Products',
    mr: 'Mr',
    division: 'Division',
    go: 'Go',
    review: 'Review',
    candf: 'C&F',
    update: 'Update',
    slm: 'SLM',
    flm: 'FLM',
    self: 'Self',
    other: 'Other',
    tlm: 'TLM',
    fc: 'FC',
    chemist: 'Chemist',
    inActive: 'InActive',
    productDetails: 'Product Details',
    active: 'Active',
    stockist: 'Stockist',
    customer: 'Customer',
    mrName: 'MR Name',
    approvalPeriod: 'APPROVAL PERIOD',
    standardTradeDiscount: 'Standard Trade Discount',
    additionalradeDiscount: ' Additional Trade Discount',
    totalTradeDiscount: 'Total Trade Discount',
    selectedproducts: 'Selected Products',
    selectedItems: 'Selected Items',
    unSelectedItems: 'Unselected Items',
    deletedProducts: 'Deleted Products',
    percent: '%',
    qty: ' QTY',
    mrp: 'MRP',
    pts: 'PTS',
    ptr: ' PTR',
    submit: 'Submit',
    clearAll: 'Clear All',
    UnBlock: 'Unblock',
    requestRejectionRemarks: 'Request Rejection Remarks',
    cancel: 'Cancel',
    upload: 'Upload',
    close: 'Close',
    product: 'Product',
    modify: 'Modify',
    expiryDate: 'Expiry Date',
    save: 'Save',
    confirmAction: 'Confirm Action',
    deleteConfirmation: 'Are you sure you want to delete this product?',
    delete: 'Delete',

    fcAdmin: {
      dashboard: {
        requestForm: 'Request - FORM',
        summary: 'Summary',
        updateTotalTradeDiscount: 'Update Limit',
        manageUserRole: 'Manage User Role',
        accessControl: 'Manage Product Status',
        tableHeading: {
          requestFormNo: ' Request Form No.',
          chemistandDis: 'Chemist/Dispenser',
          ReasonsforRejections: ' Reason for Rejection',
        },
      },
      placeholder: {},
      requestForm: {
        filters: {
          startDate: 'Select From Date',
          endDate: 'Select To Date',
          requestFormNo: 'Request Form No.',
          state: 'Select State',
          hq: 'Select HQ',
          mr: 'Select MR',
          rejection: '-Select Reason-',
        },
        fcAdminRemarks: 'FC ADMIN REMARKS',
        approvalStatus: 'Approval Status',
      },
      summary: {
        tableHeading: {
          totalOrders: 'TOTAL ORDERS',
          salesValue: 'SALES VALUE',
          avgExtraDis: 'AVG. EXTRA DISCOUNT',
          averageExtraDis: 'Average extra discount',
          totalSalesValue: 'Total Sales Value (in ₹)',
        },
      },
      updateLimit: {
        searchProductPlaceholder: "Search Products Here",
        monthlyQty: "Monthly QTY (C/D)",
        dailyQty: "Daily QTY (C/D)",
        fromDate: "From Date",
        uploadFile: "Upload File",
        bulkUpdateLabel: "Want to update in Bulk?",
        downloadTemplate: "Download Template",
        uploadTheFile: "Upload the file",
        dragFile: "Click or drag file here or",
        chooseFile: "Choose File",
        supportedFiles: "Supported Format : .XLSX , .CSV",
        uploadedOn: "Uploaded on",
        successfulInserts: "Successful Inserts:",
        successfulUpdates: "Successful Updates:",
        worksheet: "Worksheet",
        row: "Row",
        productId: "Product Id",
        productName: "Product Name",
        errorMessage: "Error Message"
      },
      manageUserRole: {
        requestFormApprovalHeaderText: 'ZHO Request Form Approval User Roles',
        orderApprovalHeaderText: 'ZHO Order Approval User Roles',
        user: 'User',
        currentStatus: 'Current Status',
        proposedStatus: 'Proposed Status',
      },
      manageProductStatus: {
        manageProduct: 'Manage Product',
        blockAndUblockHeading:
          'Block & Unblock product from the list given below',
        previous: 'Previous',
        next: 'Next',
        confirmation: 'Confirmation',
        modelMessage:
          'Are you sure you want to unblock this entire transaction?',
        blockingMessageSingle: 'Below combination already exists!',
        blockingMessageMultiple: 'Below combinations already exists!',
        filterMaxLimit: 'You can select maximum 3 C&F',
        deletedLog: 'Deleted Logs',
      },
      accessControl: {},
      actionButtons: {},
    },
    hoAdmin: {
      dashboard: {
        sync: 'Sync',
        tooltipSync: `Please "sync" before "approving" the order`,
        order: 'Order',
        invoicing: 'Invoicing',
        Summary: 'Summary',
        filters: {
          selectDivision: 'Select Division',
          requestFormOrder: 'Request Form/Order No.',
        },
        tableHeading: {
          orderNo: 'Order No.',
          schemeStatus: 'Scheme Status',
          SapOrderNo: 'SAP Order No.',
          SapOrderRemarks: 'SAP Order Remarks',
          InvoiceNo: 'Invoice No.',
          invoiceDate: 'Invoice Date',
          supply: 'Supply',
        },
        modalPopup: {
          order: 'Order',
          fcAdmin: 'FC Admin',
          hoAdmin: 'HO Admin',
          orderFormNo: 'Order Form No.',
          supplyQty: 'Supply Qty',
          availedDiscount: 'Availed Discount',
          podNo: 'POD No.',
          invoiceQty: 'Inv Qty',
          podQty: 'POD Qty',
          podCopy: 'POD Copy',
          podDate: 'POD Date',
          supplyPod: 'Supply POD',
          sapRemarks: 'SAP Remarks',
          totalOrder: 'Total Order :',
          successfullyApproved: 'Successfully Approved :',
          successfullySynced: 'Successfully Synced :',
          viewPod: 'View POD',
          HoadminRemarks: 'HO ADMIN REMARKS',
          podRejectedRemarks: 'POD Rejected Remarks',
          rejectedProducts: 'Rejected Products',
          Attachment: 'Attachment',
          download: 'Download',
          maxFileLimit: 'Max size is 6 MB.'
        },
      },
      performanceBar: {
        performance: 'Performance',
        ho: 'HO',
        pod: 'POD',
      },
      pod: {
        viewPod: 'View POD',
        uploadPod: 'Upload POD',
      },
      placeholder: {},
      requestForm: {},
      summary: {},
      updateTotalTradeDiscount: {},
      manageUserRole: {},
      accessControl: {},
      actionButtons: {},
    },
  },
};
